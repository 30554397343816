import request from '../../utils/request'
// 招聘岗位详情
export function getUserLog(data = {}) {
  return request({
    url: `website/recordapply/getRecordApplyListByStaffId`,
    method: 'get',
    params: data
  })
}
// 提交
export function getSubmit(data = {}) {
  return request({
    url: `/website/recordapply/apply`,
    method: 'post',
    data: data
  })
}
// 根据id查询
// export function getaccording(id = '') {
//   return request({
//     url: `/website/staff/info/` + id,
//     method: 'get',
//     item: 'item'
//   })
// }

