<template>
  <div class="main">
    <el-dialog
      :title="'职位申请'"
      :visible.sync="dialogFormVisible"
      :before-close="handleBeforeClose"
    >
      <el-form :model="form" size="small" :rules="rules">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="手机号" :label-width="formLabelWidth">
          <el-input v-model="form.phone" autocomplete="off" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="简历" :label-width="formLabelWidth">
          <el-upload
            class="upload-demo"
            action="/api/hnhz/file/upload"
            :on-preview="handlePreview"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :file-list="fileList"
            :limit="1"
          >
            <el-button v-if="fileList.length === 0" size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleBeforeClose">取 消</el-button>
        <el-button type="primary" :loading="subLoading" size="small" @click="handleSubmit()">确 定</el-button>
      </div>
    </el-dialog>
    <div class="recuritbanner">
      <img src="../assets/banner.png" alt style="height: 489px;width:100%">
    </div>
    <el-card class="box-card card">
      <router-link to="/recruit">
        <div class="icon">
          <i class="el-icon-back" />
        </div>
      </router-link>

      <div class="cardcontent">
        <div>
          <div class="position">
            <div>{{ data.jobTitle }}</div>
          </div>
          <div class="salary">
            <div class="full-time">全职</div>
            <div class="qian">15K-23K</div>
          </div>
          <div class="city">湖南省长沙市</div>
        </div>
        <div class="apply">
          <el-button type="text" @click="applys">申请职位</el-button></div>
      </div>
      <div class="post">
        <div class="responsibilities">
          <el-divider content-position="left">岗位职责</el-divider>
          <div class="duty">
            <!-- {{data.responsibilities}} -->
            <div v-html="data.responsibilities.replaceAll('\n', '<br/>')">}</div>
          </div>
        </div>
        <div class="jobRequirements">
          <el-divider content-position="left">岗位要求</el-divider>
          <div class="requirement">
            <!-- {{ data.jobRequirements }} -->
            <div v-html="data.jobRequirements.replaceAll('\n', '<br/>')">}</div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
// import { getUsermanagement } from '@/api/admin/homePage'
import { getDetails } from '../api/pc/home'
import { getSubmit } from '@/api/admin/recruit'

export default {
  data() {
    return {
      data: {},
      dialogFormVisible: false,
      subLoading: false,
      form: {
        name: '',
        phone: '',
        resumeUrl: '',
        staffId: ''
      },
      fileList: [],
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    console.log(this.$route)
    getDetails(this.$route.query.id)
      .then(res => {
        console.log('res', res)
        this.data = res.data.staff
      })
      .catch(e => {
        console.error(e)
      })
  },
  methods: {
    // 显示提交表单
    applys() {
      this.dialogFormVisible = true
      this.form.staffId = this.$route.query.id
      // this.form.staffId = row.id
    },
    // 点击取消，弹窗关闭
    handleClose() {
      this.dialogFormVisible = false
      this.form = {}
      this.fileList = []
    },
    // 提交
    handleSubmit() {
      this.subLoading = true
      getSubmit(this.form).then(res => {
        this.subLoading = false
        this.$message({
          message: '提交成功',
          type: 'success'
        })
        this.handleClose()
        this.fileList = []
      }).catch(e => {
        this.subLoading = false
        // this.$message({
        //   message: '提交失败',
        //   type: 'danger'
        // })
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      this.form.resumeUrl = ''
    },
    // 上传文件
    handlePreview(file, fileList) {
      console.log(file, fileList)
    },
    // 上传文件
    handleAvatarSuccess(res, file) {
      console.log('handleAvatarSuccess', res, file)
      this.form.resumeUrl = res.data.filePath
      // this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleBeforeClose(done) {
      this.$confirm('关闭后未保存的数据将会丢失，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.handleClose()
        })
    }
  }
}

</script>
<style lang="scss" scoped>
.main {
  margin: 0 auto;
  .box-card {
    width: 60%;
    margin: 27px 20%;
    border-radius: 5px;
    background: white;
    -webkit-box-shadow: 0px 2px 12px rgb(0 0 0 / 10%);
    box-shadow: 0px 2px 12px rgb(0 0 0 / 10%);
    display: -webkit-box;
    display: -ms-flexbox;
    text-align: left;
  }
  .card {
    .icon{
          font-size: 23px;
    margin-left: 15px;
    }
    .icon:hover {
        color: red;
      }
    .cardcontent {
          margin-top: 21px;
      // width: 800px;
      padding: 20px;
      display: flex;
      .apply {
        margin-left: auto;
        margin-top: 9px;
      }
      .position {
        display: flex;
        font-size: 29px;
      }
    }
    .post {
      padding: 20px;
      .responsibilities{
        .duty{
          line-height: 47px;
        }
      }
      .jobRequirements{
        margin-top:100px;
        .requirement{
          line-height:47px;
        }
      }
    }
    .salary {
      display: flex;
      margin-top: 10px;
      .full-time {
        color: rgb(168, 170, 174);
      }
      .qian {
        color: rgb(197, 150, 84);
        margin-left: 10px;
      }
    }
    .city {
      color: rgb(153, 157, 165);
      margin-top: 10px;
    }
  }
}
</style>
<style lang="scss">
.main {

}
</style>
