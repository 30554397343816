import { render, staticRenderFns } from "./recruitcontent.vue?vue&type=template&id=5094311d&scoped=true&"
import script from "./recruitcontent.vue?vue&type=script&lang=js&"
export * from "./recruitcontent.vue?vue&type=script&lang=js&"
import style0 from "./recruitcontent.vue?vue&type=style&index=0&id=5094311d&lang=scss&scoped=true&"
import style1 from "./recruitcontent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5094311d",
  null
  
)

export default component.exports